import React, { useEffect, useState } from 'react';
import GoBackButton from '../goBack/GoBackButton';
import CeoNavbarComponent from './CeoNavbar';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import config from '../../config/config';
import { apiHeaderToken } from '../../config/api_header';
import CircularProgressWithLabel from '../job/JobCartsDetails/CirculProgressBar';
import { DataGrid } from '@mui/x-data-grid';

const ListOfInterviewedCandidateOfCeo = () => {

    const [candidate , setCandidate] = useState(null)
    const [searchParams] = useSearchParams()


    useEffect(() => {

        if(searchParams.get('type')){
            ( async () => {

                try {
                    const type = searchParams.get('type')
                    const payloads = {
                        "keyword":"",
                        "page_no":"1",
                        "per_page_record":"1000",
                        "scope_fields":[],
                        "filter_type":type,
                        "job_id":""
                    }

                    const response = await axios.post(`${config.API_URL}getApplyJobListCeo` , payloads , apiHeaderToken(config.API_TOKEN))

                    if(response.status === 200){
                        setCandidate(response.data.data)
                    }

                } catch (error) {

                    console.log(error)
                    
                }

            })()
        }

    } , [searchParams])


    const rows = candidate && candidate.length !== 0
    ? candidate?.map((value, index) => ({
            id: index + 1,
            candidateInfo: {
                candidate_id: value._id,
                status: value?.applied_jobs?.find((item) => item?.job_id === value?.job_id )?.form_status,
                name: value?.name?.length > 25 ? value?.name.slice(0, 25) + "..." : value?.name,
                email: value?.email,
                phone: value?.mobile_no,
                match_percent: parseInt(value.complete_profile_status),
                resume: value?.resume_file,
                job_id: value?.job_id,
                applied_job_id: value?.applied_jobs?.find((item) => item?.job_id === value?.job_id )?._id,
                interviewer_id: value?.applied_jobs
            },
            "currentDesignation": value.designation,
            "Experience": value.total_experience,
            "Location": value?.location ? value?.location : 'N/A',
            "Current CTC": `${value.current_ctc ? value.current_ctc : 0} LPA`,
            "Expected CTC": `${value.expected_ctc ? value.expected_ctc : 0} LPA`,
            "Notice Period": `${value.notice_period ? value.notice_period : 0} Days`,
            project_name: value?.project_name,
            batch_id: value?.batch_id ? value?.batch_id : 'N/A',
            applied_date: value?.add_date
        }))
    : [];


    const columns = [
        { field: "id", headerName: "Sno.", width: 50 },
        {
            field: "candidateName",
            headerName: "Candidate Name",
            width: 250,
            renderCell: (params) => (
                <div className="candinfo prcnt_bar">
                    <p className="color-blue overflow-hidden">{params.row?.candidateInfo?.name}</p>
                    <span className="statustag">{params?.row?.candidateInfo?.status}</span>
                    <CircularProgressWithLabel percentage={params?.row?.candidateInfo?.match_percent} />
                </div>
            ),
        },
        {
            field: "Email & Phone Number",
            headerName: "Email & Phone Number",
            width: 250,
            renderCell: (params) => (
                <div className="candinfo">
                    <p className="overflow-hidden">{params.row?.candidateInfo?.email}</p>
                    <span>{params?.row?.candidateInfo?.phone}</span>
                </div>
            ),
        },
        {
            field: "applied_date",
            headerName: "Applied Date",
            width: 250,
            renderCell: (params) => (
                <div className="candinfo over">
                    <span>{params?.row?.applied_date}</span>
                </div>
            ),
        },
        {
            field: "batch_id",
            headerName: "Batch Id",
            type: "number",
            width: 100,
        },
        {
            field: "currentDesignation", // Ensure it matches your data field key
            headerName: "Current Designation",
            type: "string", // Use 'string' for text-based data
            width: 250, // Or use flex for dynamic resizing
            renderCell: (params) => (
                <span>
                    <p className="overflow-hidden" style={{ textOverflow: 'ellipsis' }}>{params?.row?.currentDesignation}</p>
                </span>
            ),
        },
        {
            field: "project_name",
            headerName: "Project Name",
            type: "string",
            width: 250,
        },
        {
            field: "Experience",
            headerName: "Experience",
            type: "number",
            width: 150,
        },
        {
            field: "Location",
            headerName: "Location",
            type: "number",
            width: 120,
            renderCell: (params) => (
                <span>
                    <p className="overflow-hidden" style={{ textOverflow: 'ellipsis' }}>{params?.row?.currentDesignation}</p>
                </span>
            ),
        },
        {
            field: "Current CTC",
            headerName: "Current CTC",
            type: "number",
            width: 90,
        },
        {
            field: "Expected CTC",
            headerName: "Expected CTC",
            type: "number",
            width: 90,
        },
        {
            field: "Notice Period",
            headerName: "Notice Period",
            type: "number",
            width: 80,
        }

    ].filter(Boolean);

    return (
        <> 
            {/* <CeoNavbarComponent /> */}
       
            <div className="maincontent">
                <div className="container hrdashboard" data-aos="fade-in" data-aos-duration="3000">
                    <GoBackButton />
                    <div className='dflexbtwn'>
                        <div className='pagename'>
                            <h3>List of Interview(s) Candidates</h3>
                            <p> Interview(s) Candidates {searchParams.get('type')} Feedback Lisitng  </p>
                        </div>
                    </div>
                    <div className='row'>
                                    <div className="w-100" >
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            headerClassName="custom-header-class"
                                            initialState={{
                                                pagination: {
                                                    paginationModel: { page: 0, pageSize: 10 },
                                                },
                                            }}
                                            rowHeight={80}
                                            pageSizeOptions={[10, 20]}
                                            // checkboxSelection={PageStatus}
                                            // onRowSelectionModelChange={handleSelectionChange} // Updated method name    
                                            // loading={AppliedJobs.status === 'loading' && true}
                                            sx={{
                                                minHeight: 400
                                            }}
                                        />
                                    </div>

                    </div>
                </div>
            </div>
        </>
    )
}


export default ListOfInterviewedCandidateOfCeo;
