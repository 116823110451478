import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography, Button, List, ListItem, ListItemText, CircularProgress } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { JsonDataParsed, uploadsFiles } from "../slices/ImportCandidateSlice/ImportCandidate";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

const REQUIRED_FIELDS = [
    "email",
    "name",
    "mobile_no",
];


const UploadsExcelFile = () => {
    // const [file, setFile] = useState(null);
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);

    const { file, extractedData } = useSelector((state) => state.import)


    const dispatch = useDispatch();

    const convertFileToJson = async (file) => {
        return new Promise((resolve, reject) => {
            if (!file) return reject("No file selected.");

            const reader = new FileReader();

            reader.onload = (event) => {
                const fileData = event.target.result;

                try {
                    const workbook = XLSX.read(fileData, { type: "binary" });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];

                    const rawData = XLSX.utils.sheet_to_json(worksheet, {
                        header: 1,
                        defval: "",
                        raw: true
                    });

                    if (rawData.length === 0) return reject("Empty file!");

                    const headers = rawData[0].map((header, index) => header || `Empty_Column_${index + 1}`);

                    const formattedData = rawData.slice(1).map(row => {
                        return headers.reduce((obj, header, index) => {
                            obj[header] = row[index] !== undefined ? row[index] : "";
                            return obj;
                        }, {});
                    });

                    setLoader(false)

                    resolve(formattedData);
                } catch (error) {
                    reject("Error processing file: " + error.message);
                }
            };

            reader.onerror = () => reject("File reading error");
            reader.readAsBinaryString(file);
        });
    };


    const onDrop = async (acceptedFiles) => {

        setLoader(true)

        const validTypes = [
            "text/csv",
            "application/vnd.ms-excel", // .xls
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
        ];

        const selectedFile = acceptedFiles[0];

        if (selectedFile && validTypes.includes(selectedFile.type)) {
            dispatch(uploadsFiles(selectedFile))
            try {
                const data = await convertFileToJson(selectedFile);
                dispatch(JsonDataParsed(data))
            } catch (error) {
                return toast.error('Error processing file: ' + error.message);
            }
            setError("");
        } else {

            dispatch(uploadsFiles(null))
            setLoader(false)
            setError("Invalid file type. Please upload a CSV or Excel file (.csv, .xls, .xlsx).");
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: ".csv, .xls, .xlsx",
        multiple: false,
    });

    // Total records count
    const totalRecords = (extractedData || Array.isArray(extractedData)) && extractedData.length;

    const errorRecords = extractedData && extractedData
    .map((record, rowIndex) => {
        const missingRequiredFields = [];

        // Check only required fields
        REQUIRED_FIELDS.forEach((field, index) => {
            const value = record[field];

            // If the required field is missing or empty
            if (value === undefined || value === null || (typeof value === "string" ? value.trim() === "" : false)) {
                missingRequiredFields.push(
                    <span key={field}>
                        {field}
                        <span className='text-danger'>*</span>
                        {index < REQUIRED_FIELDS.length - 1 && <span style={{ margin: "0 5px" }}> , </span>}
                    </span>
                );
            }
        });

        if (missingRequiredFields.length > 0) {
            return (
                <div key={rowIndex}>
                    Row {rowIndex + 1}: {missingRequiredFields}
                </div>
            );
        }

        return null;
    })
    .filter(Boolean);  // Filter out rows with no errors


    return (
        <Box
            sx={{
                border: "2px dashed #1976d2",
                padding: "20px",
                textAlign: "center",
                borderRadius: "10px",
                backgroundColor: isDragActive ? "#f0f8ff" : "#fafafa",
                transition: "background-color 0.3s",
            }}
            {...getRootProps()}
        >
            <input {...getInputProps()} />
            <CloudUploadIcon sx={{ fontSize: 50, color: "#1976d2" }} />
            <Typography variant="h6" mt={2}>
                {isDragActive ? "Drop the Excel file here..." : "Drag & Drop or Click to Upload an Excel file"}
            </Typography>
            <Button variant="contained" sx={{ mt: 2 }}>
                Browse File
            </Button>

            {/* Error Message */}
            {loader && <Typography sx={{ mt: 2 }} color="error" mt={2}>  <CircularProgress /> </Typography>}
            {!loader && error && <Typography sx={{ mt: 2 }} color="error" mt={2}>{error}</Typography>}

            {
                !loader && file && extractedData && <Typography sx={{ mt: 2 }} variant="h6">Data Summary</Typography>
            }

            {/* File Info */}
            {!loader && file && (
                <List sx={{ mt: 2 }}>
                    <ListItem>
                        <ListItemText primary={file.name} secondary={(file.size / 1024).toFixed(2) + " KB"} />
                    </ListItem>
                </List>
            )}
            {
                !loader && extractedData && (
                    <>
                        <List>
                            <ListItem>
                                <ListItemText primary={`Total Records: ${totalRecords}`} />
                            </ListItem>

                            {errorRecords.length > 0 && (
                                <>
                                    <Typography variant="subtitle1" color="error">
                                        Errors (Missing Required Fields):
                                    </Typography>
                                    {errorRecords.map((error, index) => (
                                        <ListItem key={index}>
                                            <ListItemText primary={error} />
                                        </ListItem>
                                    ))}
                                </>
                            )}
                        </List>
                    </>
                )
            }
        </Box>
    );
};

export default UploadsExcelFile;
