import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { IoCloudUploadOutline, IoDocumentTextOutline } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import { FiUserCheck } from "react-icons/fi";
import { IoCalendarClearOutline } from "react-icons/io5";
import { RiDeleteBin6Line } from "react-icons/ri";
import { RiFeedbackLine } from "react-icons/ri";
import { GrSubtractCircle } from "react-icons/gr";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import config from "../../../config/config";
import { ShortListCandidates } from "../../slices/JobSortLIstedSlice/SortLIstedSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { FetchAppliedCandidateDetails } from "../../slices/AppliedJobCandidates/JobAppliedCandidateSlice";
import { DeleteAndRemoved } from "../../slices/JobSortLIstedSlice/SortLIstedSlice";
import { FaUserPlus } from 'react-icons/fa';
import { useRef } from "react";
import axios from "axios";
import { apiHeaderTokenMultiPart } from "../../../config/api_header";
import { toast } from "react-toastify";
import CircularProgressWithLabel from "./CirculProgressBar";


export default function JobCandidateTable({ PageStatus, setCandidatesDetials, filterText }) {
    const dispatch = useDispatch();
    const { id } = useParams();
    const AppliedJobs = useSelector((state) => state.appliedJobList.AppliedCandidate)
    const filterJobDetails = useSelector((state) => state.appliedJobList.selectedJobList);
    const navigation = useNavigate()
    
    const fileInputRef = useRef({});

    const getEmployeeRecords = JSON.parse(localStorage.getItem('admin_role_user') ?? {})
    const rows = AppliedJobs.status === 'success' && AppliedJobs.data.length !== 0
        ? AppliedJobs.data
            .filter(value => {
               return value?.applied_jobs?.some(item => PageStatus === "" || item?.form_status === PageStatus)
                // return PageStatus === "" || value.form_status === PageStatus;
            })
            .map((value, index) => ({
                id: index + 1,
                candidateInfo: {
                    candidate_id: value._id,
                    status: value?.applied_jobs?.find((item) => item?.job_id === id || filterJobDetails?.value || value?.job_id )?.form_status,
                    name: value?.name?.length > 25 ? value?.name.slice(0, 25) + "..." : value?.name,
                    email: value?.email,
                    phone: value?.mobile_no,
                    match_percent: parseInt(value.complete_profile_status),
                    resume: value?.resume_file,
                    job_id: value?.job_id,
                    applied_job_id: value?.applied_jobs?.find((item) => item?.job_id === id || filterJobDetails?.value ||  value?.job_id )?._id,
                    interviewer_id: value?.applied_jobs
                },
                "currentDesignation": value.designation,
                "Experience": value.total_experience,
                "Location": value?.location ? value?.location : 'N/A',
                "Current CTC": `${value.current_ctc ? value.current_ctc : 0} LPA`,
                "Expected CTC": `${value.expected_ctc ? value.expected_ctc : 0} LPA`,
                "Notice Period": `${value.notice_period ? value.notice_period : 0} Days`,
                project_name: value?.project_name,
                batch_id: value?.batch_id ? value?.batch_id : 'N/A',
                applied_date: value?.add_date
            }))
        : [];

    // shortListCandidate
    function handleSortListed(e, data) {

        if(!id && !filterJobDetails){
            return toast.warn('Please Select the Job')
        }

        e.preventDefault();
        let candidateDetails = [
            {
                candidate_id: data?.candidateInfo.candidate_id,
                applied_job_id: data?.candidateInfo.applied_job_id
            }
        ]
        let payloads = {
            "role_user_id": getEmployeeRecords?._id,
            "candidate_ids": candidateDetails,
            "status": "Shortlisted"
        }
        dispatch(ShortListCandidates(payloads))
            .unwrap()
            .then((response) => {
                if (response.status) {
                    dispatch(FetchAppliedCandidateDetails(id || filterJobDetails?.value));
                }
            })
            .catch(err => {
                console.log(err);
            })
    }


    // Delete the Candidate with our Profile
    const handleDelete = (e, data) => {

        if(!id && !filterJobDetails){
            return toast.warn('Please Select the Job')
        }
        e.preventDefault();
        let payloads = {
            "candidate_id": data?.candidateInfo.candidate_id,
            "applied_job_id": data?.candidateInfo.applied_job_id,
            "status": "Deleted"
        }
        dispatch(DeleteAndRemoved(payloads))
            .unwrap()
            .then((response) => {
                if (response.status) {
                    dispatch(FetchAppliedCandidateDetails(id || filterJobDetails?.value));
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    // Reject Candidate
    const handleReject = (e, data) => {

        if(!id && !filterJobDetails){
            return toast.warn('Please Select the Job')
        }
        e.preventDefault();
        let payloads = {
            "candidate_id": data?.candidateInfo.candidate_id,
            "applied_job_id": data?.candidateInfo.applied_job_id,
            "status": "Rejected"
        }
        dispatch(DeleteAndRemoved(payloads))
            .unwrap()
            .then((response) => {
                if (response.status) {
                    dispatch(FetchAppliedCandidateDetails(id || filterJobDetails?.value));
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleNavigationOnProfile = (url) => {

        if(!id && !filterJobDetails){
            return toast.warn('Please Select the Job')
        }
        navigation(url)
    }

    const columns = [
        { field: "id", headerName: "Sno.", width: 50 },
        {
            field: "candidateName",
            headerName: "Candidate Name",
            width: 250,
            renderCell: (params) => (
                <div className="candinfo prcnt_bar">
                   <p style={{cursor:'pointer'}} onClick={()=> handleNavigationOnProfile(`/candidate-profile/${params.row?.candidateInfo?.candidate_id}?job_id=${id || filterJobDetails?.value}`)} className="color-blue overflow-hidden">{params.row?.candidateInfo?.name}</p>
                    <span className="statustag">{params?.row?.candidateInfo?.status}</span>
                    {
                        PageStatus === 'Shortlisted' ? null :
                            // <div className="percnt_match">
                                // <div className="percnt_match_progress">
                                //     {/* <span className="title timer" data-from="0" data-to={params?.row?.candidateInfo?.match_percent} data-speed="1800">{params?.row?.candidateInfo?.match_percent}%</span>
                                //     <div className="left"></div>
                                //     <div className="right"></div>
                                //     <div className="top"></div> */}
                                //     {/* <CircularProgressWithLabel percentage={params?.row?.candidateInfo?.match_percent} /> */}

                                // </div>
                                <CircularProgressWithLabel percentage={params?.row?.candidateInfo?.match_percent} />

                            // </div>
                    }
                </div>
            ),
        },
        {
            field: "Email & Phone Number",
            headerName: "Email & Phone Number",
            width: 250,
            renderCell: (params) => (
                <div className="candinfo">
                    <p className="overflow-hidden">{params.row?.candidateInfo?.email}</p>
                    <span>{params?.row?.candidateInfo?.phone}</span>
                </div>
            ),
        },

        PageStatus === 'Applied' &&
        {
            field: "applied_date",
            headerName: "Applied Date",
            width: 250,
            renderCell: (params) => (
                <div className="candinfo over">
                    <span>{params?.row?.applied_date}</span>
                </div>
            ),
        },
        {
            field: "batch_id",
            headerName: "Batch Id",
            type: "number",
            width: 100,
        },
        {
            field: "currentDesignation", // Ensure it matches your data field key
            headerName: "Current Designation",
            type: "string", // Use 'string' for text-based data
            width: 250, // Or use flex for dynamic resizing
            renderCell: (params) => (
                <span>
                    <p className="overflow-hidden" style={{ textOverflow: 'ellipsis' }}>{params?.row?.currentDesignation}</p>
                </span>
            ),
        },
        {
            field: "project_name",
            headerName: "Project Name",
            type: "string",
            width: 250,
        },
        {
            field: "Experience",
            headerName: "Experience",
            type: "number",
            width: 150,
        },
        {
            field: "Location",
            headerName: "Location",
            type: "number",
            width: 120,
            renderCell: (params) => (
                <span>
                    <p className="overflow-hidden" style={{ textOverflow: 'ellipsis' }}>{params?.row?.currentDesignation}</p>
                </span>
            ),
        },
        {
            field: "Current CTC",
            headerName: "Current CTC",
            type: "number",
            width: 90,
        },
        {
            field: "Expected CTC",
            headerName: "Expected CTC",
            type: "number",
            width: 90,
        },
        {
            field: "Notice Period",
            headerName: "Notice Period",
            type: "number",
            width: 80,
        },
        {
            field: "Resume",
            headerName: "Resume",
            width: 80,
            renderCell: (params) => {
                const resume = params.row?.candidateInfo?.resume;
                const isResumeAvailable = resume && resume.trim() !== ''; // Check if resume is valid (not empty or undefined)

                const handleResumeUploadClick = () => {
                    fileInputRef.current[params.row?.candidateInfo?.candidate_id].click();
                };

                // Function to handle file selection
                const handleFileChange = async (event, candidate_id) => {
                    const file = event.target.files[0];
                    if (file) {
                        let payloads = {
                            "candidate_id": candidate_id,
                            "old_resume_file": "",
                            "filename": file
                        }
                        try {
                            let response = await axios.post(`${config.API_URL}uploadResume`, payloads, apiHeaderTokenMultiPart(config.API_TOKEN))
                            if (response.status === 200) {
                                toast.success(response?.data?.message)
                                dispatch(FetchAppliedCandidateDetails(id));
                            } else {
                                toast.error(response?.data?.message)
                            }
                        } catch (error) {
                            toast.error(error.response.data.message || error?.message);
                        }
                    }
                };

                return (
                    <div className="d-flex justify-content-end align-items-center gap-3">
                        <div className="h-100" onClick={handleResumeUploadClick} style={{ cursor: 'pointer' }}>
                            <IoCloudUploadOutline className="fs-5" color="red" />
                            <input
                                type="file"
                                ref={(el) => (fileInputRef.current[params.row?.candidateInfo?.candidate_id] = el)} // Store the ref in the object
                                style={{ display: 'none' }} // Hide the input
                                onChange={(event) => handleFileChange(event, params.row?.candidateInfo?.candidate_id)} // Trigger on file selection
                                accept="application/pdf, .doc, .docx" // You can restrict file types if necessary
                            />
                        </div>

                        <div>
                            <a href={`${config.IMAGE_PATH}${resume}`} target="_blank" rel="noopener noreferrer">
                                <div className="h-100">
                                    <IoDocumentTextOutline className="fs-5" /> {/* Resume icon */}
                                </div>
                            </a>
                        </div>
                    </div>
                );
            }
        },
        {
            width: 30,
            renderCell: (params) => (
                <div className="d-flex flex-column justify-content-end align-items-center">
                    <div className="h-100 buttnner">
                        <Dropdown className="tbl_dropdown">
                            <Dropdown.Toggle>
                                <BsThreeDotsVertical className="fs-5" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="py-2 min-widther mt-2">
                                {
                                    params.row?.candidateInfo?.status === "Applied"
                                    &&
                                    <Dropdown.Item onClick={(e) => handleSortListed(e, params.row)}>
                                        <div className="d-flex">
                                            <FiUserCheck />
                                            <span>Shortlist</span>
                                        </div>
                                    </Dropdown.Item>
                                }
                                {
                                    params.row?.candidateInfo?.status === "Shortlisted"
                                    &&
                                    <Dropdown.Item href={`/schedule-interview/${id || filterJobDetails?.value}?userId=${params.row?.candidateInfo?.candidate_id}&applied-job-id=${params.row?.candidateInfo?.applied_job_id}`}>
                                        <div className="d-flex">
                                            <IoCalendarClearOutline />
                                            <span>Schedule Interview</span>
                                        </div>
                                    </Dropdown.Item>
                                }
                                {
                                    !['Rejected'].includes(params.row?.candidateInfo?.status) &&
                                    <Dropdown.Item onClick={(e) => handleReject(e, params.row)}>
                                        <div className="d-flex">
                                            <GrSubtractCircle />
                                            <span>Reject</span>
                                        </div>
                                    </Dropdown.Item>
                                }
                                {
                                    ['Shortlisted', 'Applied', 'Rejected'].includes(params.row?.candidateInfo?.status) &&
                                    <Dropdown.Item onClick={(e) => handleDelete(e, params.row)}>
                                        <div className="d-flex">
                                            <RiDeleteBin6Line />
                                            <span>Delete</span>
                                        </div>
                                    </Dropdown.Item>
                                }
                                {
                                    ['Hired'].includes(params.row?.candidateInfo?.status) &&
                                    <Dropdown.Item>
                                        <div className="d-flex">
                                            <FaUserPlus />
                                            <span>Onboard</span>
                                        </div>
                                    </Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            ),
        },
    ].filter(Boolean);



    const [selectedRowIds, setSelectedRowIds] = useState([])

    const handleSelectionChange = (selectionModel) => {
        const selectedData = selectionModel.map((id) =>
            rows.find((row) => row.id === id)
        );
        setSelectedRowIds(selectedData);
        setCandidatesDetials(selectedData);
    };

    const filteredRows = rows.filter((row) =>
        row.candidateInfo?.name.toLowerCase().includes(filterText.toLowerCase()) || row?.project_name.toLowerCase().includes(filterText.toLowerCase())
    );


    return (
        <>
            <div className="w-100" >
                <DataGrid
                    rows={filteredRows}
                    columns={columns}
                    headerClassName="custom-header-class"
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    rowHeight={80}
                    pageSizeOptions={[10, 20]}
                    checkboxSelection={PageStatus}
                    onRowSelectionModelChange={handleSelectionChange} // Updated method name    
                    loading={AppliedJobs.status === 'loading' && true}
                    sx={{
                        minHeight: 400
                    }}
                />
            </div>
        </>
    )
}

