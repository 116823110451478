import React, { useCallback, useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link } from 'react-router-dom';
import { PiDownloadSimpleFill } from "react-icons/pi";
// import ApprovalModal from "./ApprovalModal"
import { ManPowerAcquisitionsSlice } from '../slices/JobSortLIstedSlice/SortLIstedSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import config from '../../config/config';
import { FaEye } from 'react-icons/fa';
import Slide from '@mui/material/Slide';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from '@mui/material';
import axios from 'axios';
import { apiHeaderToken } from '../../config/api_header';
import { toast } from 'react-toastify';
import { GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
// import ReSendMprProject from './ReSendModalMPR';
// import AlertDialogSlide from './CeoConfirmatioModal';





const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const CustomToolbar = ({ csvOptions }) => (
    <GridToolbarContainer>
        {/* Export Button */}
        {/* <GridToolbarExport csvOptions={csvOptions} /> */}
        {/* Quick Filter for Search */}
        <GridToolbarQuickFilter />
    </GridToolbarContainer>
);



export default function RequisitionTableCeo() {
    const [visible, setVisible] = useState(false);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [searchParams] = useSearchParams()
    const [imageUrl, setImageUrl] = useState(null);
    const [projectDetails, setProjectDetials] = useState(null);

    const navigate = useNavigate();

    const [confirmation, setOpenConfirmation] = React.useState(false);
    const [formData, setFormData] = useState(null);
    const [reSendOpen, setReSendOpen] = useState(false);
    const userDetails = JSON.parse(localStorage.getItem('admin_role_user')) || {}
    const [loading, setAllSendLoading] = useState(false)
    const [mprDocId, setMPRdocId] = useState(null)
    const [confirm, setConfirm] = useState(false);
    const [getReqisitionDataToSendEmailForCEO, setCEOData] = useState(null);
    const [fetchRecordLoading, setFetchRecordLoading] = useState(false);
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
    const [total, setTotalRecords] = useState(0);


    const [filterModel, setFilterModel] = useState({
        items: [],
    });

    const handleFilterModelChange = (newFilterModel) => {
        setFilterModel(newFilterModel);
    };

    const handleClickOpen = (data) => {
        setOpenConfirmation(true);
        setFormData(data)
    };

    const handleClose = () => {
        setOpenConfirmation(false);
    };


    const { ManPowerRequisition } = useSelector((state) => state.shortList)


    useEffect(() => {
        let Payloads = {
            "keyword": "",
            page_no: paginationModel.page + 1,
            per_page_record: paginationModel.pageSize,
            "scope_fields": [],
            "status": "",
            "filter_type": searchParams.get('type') ? searchParams.get('type') : '',
            "filter_keyword": filterModel?.quickFilterValues?.join(" ").toLowerCase(),
            "project_name": searchParams.get('project_name') ? searchParams.get('project_name') : '',
            "project_id": searchParams.get('project_id') ? searchParams.get('project_id') : ''
        }
        dispatch(ManPowerAcquisitionsSlice(Payloads))
    }, [dispatch, searchParams, filterModel, paginationModel.page, paginationModel.pageSize])

    useEffect(() => {
        let Payloads = {
            "keyword": "",
            page_no: paginationModel.page + 1,
            "per_page_record": "1000", "scope_fields": [],
            isTotalCount: 'yes',
            "status": "",
            "filter_type": searchParams.get('type') ? searchParams.get('type') : '',
            "filter_keyword": filterModel?.quickFilterValues?.join(" ").toLowerCase(),
            "project_name": searchParams.get('project_name') ? searchParams.get('project_name') : '',
            "project_id": searchParams.get('project_id') ? searchParams.get('project_id') : ''
        }
        dispatch(ManPowerAcquisitionsSlice(Payloads)).unwrap()
            .then((response) => {
                if (response?.count === 'yes') {
                    setTotalRecords(response?.total)
                }
            })
    }, [dispatch, searchParams , filterModel, paginationModel.page])



    const handleShow = (e, data) => {
        e.preventDefault()
        setShow(true)
        setVisible(data);
    };

    const handleOpenPreviewImage = (e, data) => {
        setOpen(true)
        setImageUrl(data)
    }

    const changeTypeOfOpening = (type) => {
        if (type === 'new') {
            return 'New Opening'
        } else if (type === 'replacement') {
            return 'Replacement'
        } else if (type === 'plant_non_budgeted') {
            return 'Planned Addition budgeted/Non-budgeted'
        }
    }

    const handleDeleteRequisition = async () => {
        try {
            let Payloads = {
                "_id": formData?.value?._id,
                "filename": formData?.value?.requisition_form
            }
            let response = await axios.post(`${config.API_URL}deleteRequisitionDataById`, Payloads, apiHeaderToken(config.API_TOKEN))
            if (response.status === 200) {
                handleClose();
                let Payloads = {
                    "keyword": "",
                    "page_no": "1",
                    "per_page_record": "1000", "scope_fields": [],
                    "status": "",
                    "project_name": searchParams.get('project_name') ? searchParams.get('project_name') : '',
                    "project_id": searchParams.get('project_id') ? searchParams.get('project_id') : ''
                }
                dispatch(ManPowerAcquisitionsSlice(Payloads))
                toast.success(response?.data?.message)
            } else {
                toast.error(response?.data?.message)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error.message)
        }
    }


    const rows = ManPowerRequisition.status === 'success' && Array.isArray(ManPowerRequisition.data?.data)
        ? ManPowerRequisition.data?.data.map((key, index) => {
            return {
                id: index + 1 + paginationModel.page * paginationModel.pageSize,
                fID: index + 1 + paginationModel.page * paginationModel.pageSize,
                projectDepartment: {
                    name: key?.project_name,
                    department: key?.department_name,
                },
                placeofPosting: {
                    places: key?.place_of_posting?.map((item) =>
                        `${item.location_name}, ${item?.state_name ? item?.state_name : ''}`).join(','),
                },
                Posting_Location: key?.place_of_posting?.map((item) =>
                    `${item.location_name}, ${item?.state_name ? item?.state_name : ''}`).join(','),
                status: {
                    value1: key?.status,
                    value2: 'CEO Approval Pending',
                    value3: 'HOD Approval Pending',
                },
                feedBack: {
                    comment_1: "Interpersonal skills are great. Technically sound",
                    comment_2: "Matching the skill sets we required. Considering for CEO round",
                },
                value: key,
                dateofRequest: moment(key?.raised_on).format('DD MMM YYYY'),
                designation: key?.designation_name,
                type: changeTypeOfOpening(key?.type_of_opening),
                timeFrame: key?.vacancy_frame,
                noofVacancies: key?.no_of_vacancy,
                NoticePeriod: key?.vacancy_frame,
                Title: key?.title ? key?.title : 'N/A',
                Project_Name: key?.project_name,
                Department: key?.department_name,
                Status: key?.status,
                Filename: key?.requisition_form,
                "project_duration": key?.project_duration
            };
        }) : [];

    // Get Single Project By Id Base in the Requision for Data ->>>
    const getSingleProjectById = async (id) => {
        try {

            let paylods = {
                "_id": id,
                "scope_fields": ["in_charge_list", "manager_list", "_id"]
            }
            setFetchRecordLoading(true)

            let response = await axios.post(`${config.API_URL}getProjectById`, paylods, apiHeaderToken(config.API_TOKEN));
            if (response.status === 200) {
                setProjectDetials(response.data?.data)
                setFetchRecordLoading(false)
            } else {
                toast.error(response?.data?.message)
                setFetchRecordLoading(false)
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message || "Someting went wrong");
            setFetchRecordLoading(false)
        }
    }

    const handleResendMPR = async (data, GetmprData = null) => {

        // Open the modal in case of HOD
        if (GetmprData && GetmprData?.project_id && data?.name === 'HOD') {
            getSingleProjectById(GetmprData?.project_id)
            setMPRdocId(GetmprData?._id)
            setReSendOpen(true);
        }
        // Send the Email if the Case Are CEO
        else if (GetmprData && GetmprData?.project_id && data?.name === 'CEO') {
            setConfirm(true);
            setCEOData(GetmprData)
        }
    }

    // Re-send Mpr Email To CEO  
    const handleSendMPRToEmailToCEO = useCallback(async (data = '') => {
        if (!data) {
            return;
        }
        try {
            setAllSendLoading(true)
            let Payloads = {
                "mpr_doc_id": data?._id,
                "project_id": data?.project_id,
                "add_by_name": userDetails?.name,
                "add_by_mobile": userDetails?.mobile_no,
                "add_by_designation": userDetails?.designation,
                "add_by_email": userDetails?.email
            }
            let response = await axios.post(`${config.API_URL}sendRequisitionApprovalEmailToCeo`, Payloads, apiHeaderToken(config.API_TOKEN));
            if (response.status === 200) {
                toast.success(response.data.message);
                setConfirm(false)
            } else {
                toast.error(response.data.message);
            }
            setAllSendLoading(false)
        } catch (error) {
            toast.error(error?.response?.data?.message || error?.message || "Someting Went Wrong");
            setAllSendLoading(false)
        }
    }, [userDetails?.designation, userDetails?.email, userDetails?.mobile_no, userDetails?.name])

    const handleRedirectOnApprovalPage = (e, data) => {
        e.preventDefault();
        const CombineString = `${data._id}|CEO|CEO|${userDetails?.token}|CEO`;
        const encodedToken = btoa(CombineString);

        // const redirectUrl = `${config.REACT_APP_API_DOMAIN}/mprFrm/${encodedToken}`;

        setTimeout(() => {
            // window.open(redirectUrl, "_blank");
            navigate(`/mprFrm/${encodedToken}?goback=yes`)
        }, 1000);
    };



    const columns = [
        {
            field: "fID",
            headerName: "ID",
            width: 50,
            renderCell: (params) => (
                <>
                    <p className="color-blue">{params.row?.fID}</p>
                </>

            ),
        },
        {
            field: "Title",
            headerName: "MPR Number",
            width: 200,
            renderCell: (params) => (
                <>
                    <p className="color-blue">{params.row?.Title}</p>
                    {
                        params.row?.Status === 'Pending' && searchParams.get('type') === 'PendingByCeo' &&
                        <span>
                            <Button onClick={(e) => handleRedirectOnApprovalPage(e, params?.row?.value)}>
                                Approve
                            </Button>
                        </span>
                    }
                </>
            ),
        },
        {
            field: 'Filename',
            headerName: 'Filename',
            width: 100,
        },
        {
            field: 'Project_Name',
            headerName: 'Project_Name',
            width: 100,
        },
        {
            field: 'Status',
            headerName: 'Status',
            width: 100,
        },
        {
            field: 'Posting_Location',
            headerName: 'Posting_Location',
            width: 200,
        },
        {
            field: 'Department',
            headerName: 'Department',
            width: 100,
        },
        {
            field: "dateofRequest",
            headerName: "Date of Request",
            width: 200,
        },
        {
            field: "projectDepartment",
            headerName: "Project/Department", // this i dont wanna in export file
            width: 200,
            renderCell: (params) => (
                <div className='projectinfo empinfo lineBreack p-0'>
                    <p>{params.row?.projectDepartment?.name},</p>
                    <p>{params.row?.projectDepartment?.department}</p>
                </div>
            ),
        },
        {
            field: "designation",
            headerName: "Designation",
            width: 300,
        },
        {
            field: "project_duration",
            headerName: "Project Duration",
            width: 200,
        },
        {
            field: "type",
            headerName: "Type",
            type: "number",
            width: 200,
            renderCell: (params) => (
                <div className="recomd_tag lineBreack p-0">
                    <span className="">{params.row?.type}</span>
                </div>
            ),
        },
        {
            field: "timeFrame",
            headerName: "Time Frame(In Days)",
            type: "number",
            width: 200,

        },
        {
            field: "noofVacancies",
            headerName: "No. of Vacancies",
            type: "number",
            width: 200,

        },
        {
            field: "placeofPosting",
            headerName: "Place of Posting",
            type: "number",
            width: 200,
            renderCell: (params) => {
                const text = params.row?.placeofPosting?.places || '';
                const isLongText = text.length > 150;
                const displayText = isLongText ? `${text.slice(0, 150)}...` : text;
                return (
                    <Tooltip title={isLongText ? text : ''} placement="top" arrow>
                        <div className="recomd_tag lineBreack p-0">
                            <span>{displayText}</span>
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            field: "status",
            headerName: "Status", // also i wanna stutus 
            type: "number",
            width: 250,
            renderCell: (params) => (
                <div className='stats_txt lineBreack p-0'>
                    <h6 className='stats_hdng'>
                        {params.row?.status?.value1} ,
                    </h6>
                    {
                        params.row?.value?.activity_data?.length !== 0 &&
                        params.row?.value?.activity_data?.map((item, index) => {
                            return (
                                <span key={index}>
                                    <div className='d-flex'>
                                        <p>{`${item?.name} Sir ${item?.status}, `}</p>
                                        {/* {(item?.designation === 'CEO' || item?.designation === 'HOD') && item?.status !== 'Approved' && item?.status !== 'Reject' && (
                                        <Button
                                            className="apprvbtn"
                                            onClick={() => handleResendMPR(item , params.row?.value)}
                                            type='button'
                                            style={{ marginLeft: "5px", marginBottom:'5px' , fontSize:'8px' }}
                                        >
                                         Send Email
                                        </Button>
                                    )} */}
                                    </div>
                                </span>
                            );
                        })
                    }
                </div>
            ),
        },
        {
            field: "mprDoc",
            headerName: "MPR Doc", // also i wanna doc name here 
            type: "number",
            width: 120,
            renderCell: (params) => (

                <>
                    <div className='d-flex align-items-center justify-content-evenly'>

                        {
                            !(params.row?.value?.requisition_form?.includes('undefined')) && (
                                <>
                                    <a
                                        href={params.row?.value?.requisition_form}
                                        download
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <button className="downlddoc">
                                            <PiDownloadSimpleFill size={20} />
                                        </button>
                                    </a>

                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={(e) => handleOpenPreviewImage(e, params.row?.value?.requisition_form)}
                                    >
                                        <FaEye size={20} />
                                    </div>
                                </>
                            )
                        }
                    </div>
                </>

            ),
        },
        // {
        //     field: "action",
        //     headerName: "Action",
        //     type: "number",
        //     width: 130,
        //     renderCell: (params) => {
        //         const status = params.row?.value?.status;
        //         if (['Active', 'Pending'].includes(status)) {
        //             return (
        //                 <button className='apprvbtn' onClick={(e) => handleShow(e, params.row?.value)}>
        //                     Approve
        //                 </button>
        //             );
        //         }
        //         return null;
        //     }
        // },
        // {
        //     field: "Edit",
        //     headerName: "Edit",
        //     type: "number", //  removed from the export file
        //     width: 130,
        //     renderCell: (params) => {
        //         const status = params.row?.value?.status;

        //         if (['Active', 'Pending'].includes(status)) {
        //             return (
        //                 <Link to={`/manpower-acquisition?id=${params.row?.value._id}`}>
        //                     <button className='apprvbtn'>
        //                         Edit Form
        //                     </button>
        //                 </Link>
        //             );
        //         } else {
        //             return "-";
        //         }
        //     }
        // },
        // {
        //     field: "Detele",
        //     headerName: "Detele",
        //     type: "number", // removed from the export files
        //     width: 130,
        //     renderCell: (params) => {
        //         const status = params.row?.value?.status;

        //         if (['Pending'].includes(status)) {
        //             return (
        //                 <button className='danderBtb' onClick={() => handleClickOpen(params.row)}>
        //                     Delete
        //                 </button>
        //             );
        //         } else {
        //             return "-";
        //         }
        //     }
        // },
    ];

    const handlePaginationModelChange = (newPaginationModel) => {
        setPaginationModel(newPaginationModel);
    };

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        Project_Name: false,
        Department: false,
        Posting_Location: false,
        Status: false,
        Filename: false,
        Title: true,
    });





    const exportableColumns = ["fID", "Title", "dateofRequest", "Project_Name", "designation", "Department", "type", "noofVacancies", "Posting_Location", 'NoticePeriod', "timeFrame", 'Status', 'Filename'];

    return (
        <>
            <div className="mainprojecttable requstntable">
                <DataGrid
                    rows={rows}
                    columns={columns}
                    rowHeight={150}
                    initialState={{
                        columns: {
                            columnVisibilityModel: columnVisibilityModel
                        },
                    }}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) => {
                        setColumnVisibilityModel(newModel);
                    }}
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                    paginationMode="server"
                    filterMode="server"
                    pageSizeOptions={[10, 20, 50, 75, 100]}
                    loading={ManPowerRequisition.status === 'loading'}
                    rowCount={total}
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnFilter={false}
                    filterModel={filterModel}
                    onFilterModelChange={handleFilterModelChange}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    // slotProps={{
                    //     toolbar: {
                    //         csvOptions: {
                    //             fields: exportableColumns,
                    //             fileName: 'requisition-data',
                    //         },
                    //     },
                    // }}
                    sx={{
                        height: '800px',
                    }}
                />
            </div>
            {/* <ApprovalModal show={show} onHide={() => setShow(false)} data={visible} /> */}

            <Modal show={open} onHide={() => setOpen(false)} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Document Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: '100%', overflow: 'auto' }}>
                        {(() => {
                            // Check if the file is a PDF
                            const fileExtension = imageUrl && imageUrl?.split('.').pop().toLowerCase();
                            if (fileExtension === 'pdf') {
                                return (
                                    <embed
                                        src={imageUrl}
                                        type="application/pdf"
                                        width="100%"
                                        height="600px"
                                        style={{ borderRadius: '5px' }}
                                    />
                                );
                            }

                            // Check if the file is a DOC or DOCX
                            if (fileExtension === 'doc' || fileExtension === 'docx') {
                                return (
                                    <iframe
                                        src={`https://docs.google.com/viewer?url=${imageUrl}&embedded=true`}
                                        title="Document Preview"
                                        style={{ width: '100%', height: '400px' }}
                                    />

                                );
                            }

                            // Handle other file types or unknown formats
                            return (
                                <p>Unsupported file format. Please <a href={config.IMAGE_PATH + imageUrl} download>download the file</a>.</p>
                            );
                        })()}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setOpen(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* <Dialog
                open={confirmation}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Delete Requisition Form?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-requisition-description">
                        Are you sure ? you want to delete {formData && formData?.Title} requisition form.
                        This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button className='apprvbtn' onClick={handleClose}>Disagree</button>
                    <button className='danderBtb' onClick={handleDeleteRequisition}>Agree</button>
                </DialogActions>
            </Dialog> */}

            {/* Re-send Modal */}
            {/* <ReSendMprProject open={reSendOpen} setOpenClosed={setReSendOpen} Data={projectDetails} mprDocId={mprDocId} setProjectDetials={setProjectDetials} loadingToFetch={fetchRecordLoading} />
            {/* alert Diolods Alert Site ---- */}
            {/* <AlertDialogSlide open={confirm} setConfirmNoConfirm={setConfirm}  handleSendMPRToEmailToCEO={handleSendMPRToEmailToCEO} getReqisitionDataToSendEmailForCEO={getReqisitionDataToSendEmailForCEO} /> */}
        </>
    )
}

