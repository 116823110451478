import React from 'react';
import GoBackButton from '../goBack/GoBackButton';
import { Link } from 'react-router-dom';
import RequisitionTableCeo from './CeoMprTables';
import CeoNavbarComponent from './CeoNavbar';

const ListManpowerRequisitionCeo = () => {

    return (
        <> 
            {/* <CeoNavbarComponent /> */}
       
            <div className="maincontent">
                <div className="container hrdashboard" data-aos="fade-in" data-aos-duration="3000">
                    <GoBackButton />
                    <div className='dflexbtwn'>
                        <div className='pagename'>
                            <h3>List of Requisition Form</h3>
                            <p>Requisition raise request Lisitng  </p>
                        </div>
                    </div>
                    <div className='row'>
                        <RequisitionTableCeo />
                    </div>
                </div>
            </div>
        </>
    )
}


export default ListManpowerRequisitionCeo;
